
/* Set the width of the side navigation to 250px and the left margin of the page content to 250px and add a black background color to body */
function openNav() {
    document.getElementById("mySidenav").style.width = "100%";
    // document.getElementById("main").style.marginLeft = "250px";
    document.body.style.backgroundColor = "rgba(0,0,0,0.4)";
  }
    
  /* Set the width of the side navigation to 0 and the left margin of the page content to 0, and the background color of body to white */
  function closeNav() {
    document.getElementById("mySidenav").style.width = "0";
    // document.getElementById("main").style.marginLeft = "0";
    document.body.style.backgroundColor = "white";
  }
  
  // ---------------------- Slick Slider Start ---------------------- 
  $(document).ready(function(){
    
    var slidetoShow = 3;
    var windowHeight = $(window).height();

    if(windowHeight <= 425){
      slidetoShow = 1;
    }else if((windowHeight <= 900) && (windowHeight >= 425)){
      slidetoShow = 2;
    }else if(windowHeight >= 900){
      slidetoShow = 3;
    }

    $('.sidebar-items').slick({
      infinite: true,
      slidesToShow: slidetoShow,
      vertical: true,
      slidesToScroll: 1,
      autoplay: true,
      autoplaySpeed: 2000,
      prevArrow: $('.prev'),
      nextArrow: $('.next'),
    });
  
  }); 
  
  if (window.DeviceOrientationEvent) {
    window.addEventListener('orientationchange', function() { location.reload(); }, false);
  }
  
  window.onorientationchange = function()
  {
     window.location.reload();
  }
  
  // ---------------------- Slick Slider End ---------------------- 

  // ------------------------ Site Search ------------------------
  //--------------------NAV---------------------------
  $('#nav-search-input').keypress(function(e){
    if (!e) e = window.event;
    var keyCode = e.keyCode || e.which;
    if (keyCode == '13'){
      siteSearch();
    }
  })
    
  $('#nav-search').click(()=> {
    siteSearch();
  });

  function siteSearch(){
    const urlParams = new URLSearchParams(window.location.search);
    const items = urlParams.get('items');
    var searchQuery = $('#nav-search-input').val();
    var searchUrl = "/site-search?query=" + searchQuery;
    if(items === null){
    }else{
      searchUrl += "&items=" + items;
    }
    window.location = searchUrl;
  }
  //--------------------NAV END---------------------------\
  //--------------------Results Page---------------------------
  $('#advanced-search-text-input').keypress(function(e){
    if (!e) e = window.event;
    var keyCode = e.keyCode || e.which;
    if (keyCode == '13'){
      advancedSiteSearch();
    }
  })

  $('#advanced-search-button').click(()=> {
    advancedSiteSearch();
  });

  function advancedSiteSearch(){
    const urlParams = new URLSearchParams(window.location.search);
    const items = urlParams.get('items');
    var searchQuery = $('#advanced-search-text-input').val();
    var searchUrl = "/site-search?query=" + searchQuery;
    if(items === null){
    }else{
      searchUrl += "&items=" + items;
    }
    window.location = searchUrl;
  }
  //--------------------Results Page End---------------------------
  // ------------------------ Site Search End ------------------------


  //---------------Pagination-------------------------//

  //----------------Site Search----------------------
  function displaySearchItems(page, searchTerm) {
    const urlParams = new URLSearchParams(window.location.search);
    const pageOrder = urlParams.get('pageorder');

    var items = $("#items").val();
    var newPage = location.pathname + "?page=" + page + "&query=" + searchTerm;
    if(items === null){
    }else{
      newPage += "&items=" + items;
    }

    if(pageOrder == null){
    }else{
      newPage += "&pageorder=" + pageOrder;
    }
    window.location = newPage;
  }
  //----------------Site Search End----------------------

  //----------------Featured Articles----------------------
  function displayFeaturedItems(page) {
    const urlParams = new URLSearchParams(window.location.search);
    const pageOrder = urlParams.get('pageorder');

    var items = $("#items").val();
    var newPage = location.pathname + "?page=" + page;
    if(items === null){
    }else{
      newPage += "&items=" + items;
    }

    if(pageOrder == null){
    }else{
      newPage += "&pageorder=" + pageOrder;
    }
    window.location = newPage;
  }
  //----------------Featured Articles End----------------------

  //--------------Pagination End------------------------//

  //-----------------Page Order----------------------------//
  function reorderPage(){
    var order = $('#pageOrder').val();
    const urlParams = new URLSearchParams(window.location.search);
    const pageOrder = urlParams.get('pageorder');
    var url = window.location.href;

    if(window.location.search === "") // check that query params are non existent
    { // if there are no query strings add a page order query
      console.log("There are no query strings");
      window.location.href = (window.location.href + "?pageorder=" + order);
    }
    else if(pageOrder === null)
    { //if there are query strings, but no page order string, add it
      console.log("There are querystring but page order is null.");
      window.location.href = (window.location.href + "&pageorder=" + order);
    }
    else if(pageOrder != null && pageOrder != order)
    { // if there are query strings, and a page order string, update it
      console.log("There are querystring including page order, but it does not match, replacing page order.");
      window.location.href = url.replace(/(pageorder=)[^\&]+/, '$1' + order);
    }
  }
  //-----------------Page Order End-----------------------------//

  // -----------------------Sticky Menu----------------------------//
  // When the user scrolls the page, execute myFunction
  window.onscroll = function() {myFunction()};

  // Get the navbar
  var navbar = document.getElementById("nav-container");

  // Get the offset position of the navbar
  var sticky = navbar.offsetTop;

  // Add the sticky class to the navbar when you reach its scroll position. Remove "sticky" when you leave the scroll position
  function myFunction() {
    if (window.pageYOffset >= sticky) {
      navbar.classList.add("sticky-header")
    } else {
      navbar.classList.remove("sticky-header");
    }
  }
  // -----------------------Sticky Menu END----------------------------//

  function updateStartDate(){
    var startdate = $("#startdate").val();
    console.log("Start date changed to: " + startdate);
    
    const urlParams = new URLSearchParams(window.location.search);
    const urlStartDate = urlParams.get('startdate');
    var url = window.location.href;

    if(urlStartDate === null)
    { //if there are query strings, but no page order string, add it
      console.log("There are querystring but page order is null.");
      window.location.href = (window.location.href + "&startdate=" + startdate);
    }
    else if(urlStartDate != null && startdate != urlStartDate)
    { // if there are query strings, and a page order string, update it
      console.log("There are querystring including page order, but it does not match, replacing page order.");
      window.location.href = url.replace(/(startdate=)[^\&]+/, '$1' + startdate);
    }
  };

  function updateEndDate(){
    var enddate = $("#enddate").val();
    console.log("end date changed to: " + enddate);
    
    const urlParams = new URLSearchParams(window.location.search);
    const urlEndDate = urlParams.get('enddate');
    var url = window.location.href;

    if(urlEndDate === null)
    { //if there are query strings, but no page order string, add it
      console.log("There are querystring but page order is null.");
      window.location.href = (window.location.href + "&enddate=" + enddate);
    }
    else if(urlEndDate != null && enddate != urlEndDate)
    { // if there are query strings, and a page order string, update it
      console.log("There are querystring including page order, but it does not match, replacing page order.");
      window.location.href = url.replace(/(enddate=)[^\&]+/, '$1' + enddate);
    }
  };

  // $( document ).ready(function() {
  //   var menuitems = $(".dropdown-menu-right");
  //   menuitems.each(function(index){
  //     debugger;
  //     var top = $(menuitems[index]).parent().offset().top;
  //     console.log(top);
  //     console.log(menuitems[index].style.top);
  //     console.log(menuitems[index].offsetTop);
  //     menuitems[index].style.top = top + "px";
  //   });
  // });